<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Mis Clientes</h4>
            <div class="small text-muted">Listado de todos mis clientes</div>
          </b-col> 
          <b-col sm="5">
            <div>
              <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
                <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                  <i class="fa fa-filter"></i> Mostrar Filtros
                </b-button>
              </b-col>
              <b-col sm="12" class="d-none d-md-block" v-else>
                <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                  <i class="fa fa-window-close"></i>
                </b-button>
              </b-col>
            </div>
          </b-col>                             
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">

        <div>
          <b-row v-if="table.mostrarFiltros" class="mb-3">               
            <b-col sm="4">
              <b-form-group>
                <b-input-group>
                  <b-form-input type="text" size="sm" placeholder="Ingrese su busqueda" v-model="table.filter"></b-form-input>
                  <b-input-group-prepend>
                    <b-button variant="dark" size="sm" :disabled="!table.filter" @click="table.filter = ''">
                      <i class="fa fa-filter"></i> Filtrar
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
              </b-form-group>
            </b-col>          
          </b-row>
        </div>

        <b-row>
          <b-col>
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"
                    :filter="table.filter"
                    :current-page="table.currentPage"
                    :per-page="table.perPage"                    
                    :busy="table.isBusy"                    
                    v-if="table.items.length || table.isBusy">
                       
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Cargando...</strong>
                </div>
              </template>

              <template v-slot:cell(code)="row">
                <b>{{row.item.code}}</b>                              
              </template>     

              <template v-slot:cell(customers_id)="row">                
                <b-avatar :src="row.item.image"
                          v-if="row.item.image">
                </b-avatar>
                <b-avatar v-else    
                          icon="building"              
                          variant="dark">
                </b-avatar>                                  
                &nbsp;<b>{{row.item.name}}</b>
                <b-icon icon="circle-fill" v-if="!row.item.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />                
              </template>                 

              <template v-slot:cell(last_news)="row">
                <div v-html="setDateToReports(row.item, 'news')" />
              </template>
              <template v-slot:cell(last_payment)="row">
                <div v-html="setDateToReports(row.item, 'payments')" />
              </template>
              <template v-slot:cell(last_visits)="row">
                <div v-html="setDateToReports(row.item, 'visits')" />
              </template>
              <template v-slot:cell(last_services)="row">
                <div v-html="setDateToReports(row.item, 'services')" />
              </template>
              <template v-slot:cell(last_marketing)="row">
                <div v-html="setDateToReports(row.item, 'marketing')" />
              </template>
              <template v-slot:cell(last_audit)="row">
                <div v-html="setDateToReports(row.item, 'audit')" />
              </template>

              <template v-slot:cell(f_action)="row">
                <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">                                
                  <b-dropdown-item @click="goDetailCustomer(row.item)">
                    <i class="fa fa-eye"></i> Detalle Cliente
                  </b-dropdown-item>
                  <b-dropdown-header>Reportes</b-dropdown-header>
                  <b-dropdown-item @click="goReportVisit(row.item)" v-if="accessExtras.INFORME_VISITAS.status">
                    <i class="fa fa-map-marker"></i> Reporte de Visitas
                  </b-dropdown-item>
                  <b-dropdown-item @click="goReportPayment(row.item)" v-if="accessExtras.INFORME_COBRANZAS.status">
                    <i class="fa fa-dollar"></i> Reporte de Cobranzas
                  </b-dropdown-item>
                  <b-dropdown-item @click="goReportNews(row.item)" v-if="accessExtras.INFORME_NOVEDADES.status">
                    <i class="fa fa-comment"></i> Reporte de Novedades
                  </b-dropdown-item>                                                                
                </b-dropdown>
              </template>                           
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
        
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>
            <nav>
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="getRowCount(table.items)"
                            :per-page="table.perPage"
                            v-model="table.currentPage" />
            </nav>
          </b-col>          
        </b-row>
      </b-card>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'   
  import Funciones from '@/handler/funciones'   
  import moment from 'moment'

  export default {
    data: () => {
      return {      
        access: {
          module_id: Modules.VENDEDORES,
          profile_id: Profiles.PERSONAL,
          view_reference: 'listCustomersSellers',
          elements: {}
        }, 
        accessExtras:{
          INFORME_VISITAS: {
            module: Modules.INFORME_VISITAS,
            status: false
          }, 
          INFORME_COBRANZAS: {
            module: Modules.INFORME_COBRANZAS,
            status: false
          }, 
          INFORME_NOVEDADES: {
            module: Modules.INFORME_NOVEDADES,
            status: false
          },
          INFORME_SERVICIOS: {
            module: Modules.INFORME_SERVICIOS,
            status: false
          },
          INFORME_MARKETING: {
            module: Modules.INFORME_MARKETING,
            status: false
          },
          INFORME_AUDITORIA: {
            module: Modules.INFORME_AUDITORIA,
            status: false
          }                              
        }, 
        table : {
          items: [],
          fields: [],
          filter: null,
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 50,
          isBusy: false,
        },        
        arr: {        
          customers: [],
        },                          
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Configurar permisos extras para vincular modulos */
      this.accessExtras.INFORME_VISITAS.status = Helper.moduleEnabled(this.accessExtras.INFORME_VISITAS.module)
      this.accessExtras.INFORME_COBRANZAS.status = Helper.moduleEnabled(this.accessExtras.INFORME_COBRANZAS.module)
      this.accessExtras.INFORME_NOVEDADES.status = Helper.moduleEnabled(this.accessExtras.INFORME_NOVEDADES.module)
      this.accessExtras.INFORME_SERVICIOS.status = Helper.moduleEnabled(this.accessExtras.INFORME_SERVICIOS.module)
      this.accessExtras.INFORME_MARKETING.status = Helper.moduleEnabled(this.accessExtras.INFORME_MARKETING.module)
      this.accessExtras.INFORME_AUDITORIA.status = Helper.moduleEnabled(this.accessExtras.INFORME_AUDITORIA.module)
      /* Fin configuracion */
    },  
    mounted() {
      this.load()      
    },
    methods: { 
      getRowCount (items) {
        return items.length
      },          
      load() {
        this.table.isBusy = true
        var result = serviceAPI.obtenerClientesByZonas({
          sellers_id: Helper.getSeller().id
        })

        result.then((response) => {
          var data = response.data
          
          this.table.fields.push({key: 'code', label: 'Código', class: 'text-center align-middle'})
          this.table.fields.push({key: 'customers_id', label: 'Cliente', class: 'align-middle'})
          if(this.accessExtras.INFORME_VISITAS.status) {
            this.table.fields.push({key: 'last_visits', label: 'Última Visita', class: 'text-center align-middle'})
          }
          if(this.accessExtras.INFORME_COBRANZAS.status) {
            this.table.fields.push({key: 'last_payment', label: 'Última Cobranza', class: 'text-center align-middle'})
          }
          if(this.accessExtras.INFORME_NOVEDADES.status) {
            this.table.fields.push({key: 'last_news', label: 'Última Novedad', class: 'text-center align-middle'})
          }
          if(this.accessExtras.INFORME_SERVICIOS.status) {
            this.table.fields.push({key: 'last_services', label: 'Último Servicio', class: 'text-center align-middle'})
          }
          if(this.accessExtras.INFORME_MARKETING.status) {
            this.table.fields.push({key: 'last_marketing', label: 'Última Marketing', class: 'text-center align-middle'})
          }
          if(this.accessExtras.INFORME_AUDITORIA.status) {
            this.table.fields.push({key: 'last_audit', label: 'Última Auditoria', class: 'text-center align-middle'})
          }                              
          this.table.fields.push({key: 'f_action', label:'', class: 'align-middle'})          

          this.table.items = data    
          this.arr.customers = data       
          this.table.isBusy = false
        })
        .catch(error => {          
          this.table.isBusy = false
          this.$awn.alert(Error.showError(error))
        });        
      },    
      
      setDateToReports(item, type) {        
        var result = "<span class='badge badge-danger'>Sin Cargar</span>"        
        if(this.arr.customers) {          
          this.arr.customers.forEach(element => {               
            if(element.id == item.id) {
              if(element){                        
                if(type=="news") {
                  if(element["last-reports-news"]) {
                    result = "<span class='sellers-list-customers-text-dark'>" + moment.duration(moment().diff(moment(element["last-reports-news"].date),'days'),'days').humanize() + "</span>"
                  }
                }                

                if(type=="visits") {
                  if(element["last-reports-visits"]) {
                    result = "<span class='sellers-list-customers-text-dark'>" + moment.duration(moment().diff(moment(element["last-reports-visits"].date),'days'),'days').humanize() + "</span>"                 
                  }
                } 

                if(type=="payments") {
                  if(element["last-reports-payments"]) {
                    result = "<span class='sellers-list-customers-text-dark'>" + moment.duration(moment().diff(moment(element["last-reports-payments"].date),'days'),'days').humanize() + "</span>"
                  }                  
                } 

                if(type=="services") {
                  if(element["last-reports-services"]) {
                    result = "<span class='sellers-list-customers-text-dark'>" + moment.duration(moment().diff(moment(element["last-reports-services"].date),'days'),'days').humanize() + "</span>"
                  }                  
                }   
                
                if(type=="marketing") {
                  if(element["last-reports-marketing"]) {
                    result = "<span class='sellers-list-customers-text-dark'>" + moment.duration(moment().diff(moment(element["last-reports-marketing"].date),'days'),'days').humanize() + "</span>"
                  }                  
                }   
                
                if(type=="audit") {
                  if(element["last-reports-audit"]) {
                    result = "<span class='sellers-list-customers-text-dark'>" + moment.duration(moment().diff(moment(element["last-reports-audit"].date),'days'),'days').humanize() + "</span>"
                  }                  
                }                   
              }              
            }
          });
        }
        return result
      },
      
      goReportVisit(item) {
        this.$router.push({ name: 'ReportsVisitsStaffCrudReportSellerByCustomers', params: {customersID: item.id} })
      },
      goReportPayment(item) {
        this.$router.push({ name: 'ReportsPaymentStaffCrudReportSellerByCustomers', params: {customersID: item.id + '|' + item.code} })
      },
      goReportNews(item) {
        this.$router.push({ name: 'ReportsNewsStaffCrudReportSellerByCustomers', params: {customersID: item.id} })
      },
      goDetailCustomer(item) {
        this.$router.push({ name: 'CustomerViewStaff', params: {customersID: item.id} })        
      }      
    }    
  }
</script>
<style>
  .sellers-list-customers-text-dark {
    color: black;
    font-weight: 900;
  }  
  .sellers-list-customers-table-code {
    font-weight: 600;
    font-size: 11px;
  }
</style>
<style scoped>
  .b-avatar {
    width: 25px;
    height: 25px;    
  }  
</style>