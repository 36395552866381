import { render, staticRenderFns } from "./listCustomersSellers.vue?vue&type=template&id=45c3adc8&scoped=true&"
import script from "./listCustomersSellers.vue?vue&type=script&lang=js&"
export * from "./listCustomersSellers.vue?vue&type=script&lang=js&"
import style0 from "./listCustomersSellers.vue?vue&type=style&index=0&lang=css&"
import style1 from "./listCustomersSellers.vue?vue&type=style&index=1&id=45c3adc8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45c3adc8",
  null
  
)

export default component.exports